const Footer = () => {
  return (
    <footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-3 footer-logo">
            <img src="https://grpweb.com/images/logo-footer.png" alt="logo" />
          </div>
          <div className="col-sm-9 text-right">
            <ul className="social">
              <li>
                <a
                  href="https://www.instagram.com/grpltd"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/GRPReclaimRubber/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/GRPVoice"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/grp-ltd"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
            <ul className="footer-nav">
              <li>
                <a href="sitemap.html">Sitemap</a>
              </li>
              <li>
                <a href="terms-of-use.html">Terms of Use</a>
              </li>
              <li>
                <a href="disclaimer.html">Disclaimer</a>
              </li>
              <li>
                <a href="privacy-policy.html">Privacy Policy</a>
              </li>
              <li>
                <a href="join-us.html">Careers</a>
              </li>
              <li>
                <a href="download.html">Downloads</a>
              </li>
              <li>
                <a href="contact.html">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="copyright">
          © 2024 <span>GRP LTD</span>. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
