/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from "@tanstack/react-query";

import { useState } from "react";
import { getAllLevel2 } from "../level1/api";
import AddLevelTwo from "./AddLevelTwo";
import { DeleteIcon, EditIcon } from "../../../shared/Icon";
import Delete from "../Delete";

export default function InvesterLevelTwo() {
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setDeleteOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selecedRow, setSelecedRow] = useState(null);
  const { data: level1 } = useQuery({
    queryKey: ["getAllLevel2"],
    queryFn: getAllLevel2,
  });

  return (
    <>
      {openAdd && (
        <AddLevelTwo
          onClose={() => {
            setOpenAdd(false);
            setSelecedRow(null);
            setIsEditMode(false);
          }}
          isEditMode={isEditMode}
          row={selecedRow}
        />
      )}
      {openDelete && (
        <Delete
          onClose={() => {
            setDeleteOpen(false);
            setSelecedRow(null);
          }}
          reload="getAllLevel2"
          row={selecedRow}
          type={"level2"}
        />
      )}
      <div className="app-wrapper">
        <div className="app-content pt-3 p-md-3 p-lg-4">
          <div className="container-xl">
            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="d-flex flex-wrap align-items-center">
                <h1 className="app-page-title mb-0">Investor Tabs</h1>
              </div>

              <div className="col-lg-12">
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    className="btn-primary w-auto text-white"
                    onClick={() => {
                      setOpenAdd(true);
                    }}
                  >
                    Create New
                  </button>
                </div>
              </div>
              <div className="g-4 mb-4 overflow-x-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "40%" }}>
                        Name
                      </th>
                      <th scope="col" style={{ width: "40%" }}>
                        Level One Name
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {level1?.data &&
                      level1?.data.map((el: any) => (
                        <tr>
                          <td>{el.name}</td>
                          <td>{el?.levelOneName?.name}</td>
                          <td>
                            <button
                              className="btn text-primary"
                              style={{ fontSize: "18px" }}
                              onClick={() => {
                                setOpenAdd(true);
                                setIsEditMode(true);
                                setSelecedRow(el);
                              }}
                            >
                              <EditIcon />
                            </button>
                            <button
                              className="btn text-danger"
                              style={{ fontSize: "18px" }}
                              onClick={() => {
                                setSelecedRow(el);
                                setDeleteOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
