
const Header = ({onToggle}:any) => {
  return (
    <>
    <div className="header-mobile">
    <a href="#menu-toggle" id="menu-toggle" className="nav-icon" onClick={onToggle}>
      <div id="nav-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </a>
    <a href="index.html" className="brand-sm">
      <img src="https://grpweb.com/images/grp-logo.png" alt="GRP Logo" />
    </a>
    <div className="esg">
      <a href="esg-world.html"><img src="https://grpweb.com/images/esg-world.png" alt="ESG World" /></a>
    </div>
    <div className="top-midea">
      <ul className="social">
        <li><a href="https://www.instagram.com/grpltd" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
        <li><a href="https://www.facebook.com/GRPReclaimRubber/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
        <li><a href="https://twitter.com/GRPVoice" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>
        <li><a href="https://www.linkedin.com/company/grp-ltd" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
      </ul>
    </div>
  </div>
  <div>
    <div className="esg"><a href="esg-world.html"><img src="https://grpweb.com/images/esg-world.png" alt="esg"/></a></div>
    <div className="top-midea">
      <ul className="social">
        <li><a href="https://www.instagram.com/grpltd" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a></li>
        <li><a href="https://www.facebook.com/GRPReclaimRubber/" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
        <li><a href="https://twitter.com/GRPVoice" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a></li>
        <li><a href="https://www.linkedin.com/company/grp-ltd" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a></li>
      </ul>
    </div>
    </div>
    </>
  )
}

export default Header