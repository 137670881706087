import api from "../../../shared/api/index";

export const getAllLevelOne = async ({ signal }) => {
  const { data } = await api.get("/getAllLevel-one", { signal });
  return data;
};
export const addLevelOne = async ({ signal, req, isEditMode }) => {
  if (isEditMode) {
    const { data } = await api.post("/update-level", req, { signal });
    return data;
  }
  const { data } = await api.post("/add-label", req, { signal });
  return data;
};

export const addLevelTwoData = async (req, isEditMode) => {
  if (isEditMode) {
    const { data } = await api.post("/update-label-two", req);
    return data;
  }
  const { data } = await api.post("/add-label-two", req);
  return data;
};
export const getAllLevel2 = async ({ signal }) => {
  const { data } = await api.get("/getAllLevel-two", { signal });
  return data;
};

export const addLevelThreeData = async (req) => {
  const { data } = await api.post("/add-label-three", req);
  return data;
};
export const getBylevel1 = async ({ queryKey, signal }) => {
  const { data } = await api.get("/getBylevel1/" + queryKey[1], { signal });
  return data;
};
export const getBylevelTwoByLevelOne = async ({ queryKey, signal }) => {
  const { data } = await api.get("/getBylevelTwoByLevelOne/" + queryKey[1], {
    signal,
  });
  return data;
};
export const findAndDelete = async (req) => {
  const { data } = await api.post("/findAndDelete", req);
  return data;
};
export const findAndDeleteLevel3 = async (req) => {
  const { data } = await api.post("/findAndDeleteLevel3", req);
  return data;
};
export const findAndUpdatelevel3 = async (req) => {
  const { data } = await api.post("/findAndUpdatelevel3", req);
  return data;
};
