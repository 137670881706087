import { setItemInCookie, setToken } from "../../../shared/helpers/utils";
import api from "../../shared/api";
import { LoginFormProps } from "../../shared/types";

export const login = async (data: LoginFormProps) => {
  const res = await api.post("/login", data);
  setToken(res.data.token);
  setItemInCookie('role',res.data.role)
  return res;
};
