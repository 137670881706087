/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addLevelOne, getAllLevelOne } from "./api";
import { useState } from "react";
import { toast } from "react-toastify";
import { DeleteIcon, EditIcon } from "../../../shared/Icon";
import Delete from "../Delete";
// import Delete from "../Delete";j

export default function InvesterMainTopic() {
  const queryClient = useQueryClient();
  const [name, setName] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [openDelete, setDeleteOpen] = useState(false);
  const [selecedRow, setSelecedRow] = useState(null);
  const { data } = useQuery({
    queryKey: ["getAllLevel1"],
    queryFn: getAllLevelOne,
  });

  const mutate = useMutation((req) => addLevelOne({ req, isEditMode }), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAllLevel1"] });
      toast.success("Successfully Added");
      setName("");
      setIsEditMode(false);
    },
    onError: () => {
      toast.error("Failed to add ");
    },
  });
  const handleSubmit = () => {
    const newValue = name.trim();
    if (newValue.length) {
      mutate.mutate({
        name: name,
        id: isEditMode ? selecedRow._id : undefined,
      });
    } else {
      toast.error("Please Enter value");
    }
  };

  return (
    <>
      {openDelete && (
        <Delete
          onClose={() => {
            setDeleteOpen(false);
            setSelecedRow(null);
          }}
          reload="getAllLevel1"
          row={selecedRow}
          type={"level1"}
        />
      )}
      <div className="app-wrapper">
        <div className="app-content pt-3 p-md-3 p-lg-4">
          <div className="container-xl">
            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="d-flex flex-wrap align-items-center">
                <h1 className="app-page-title mb-0">Investor Tabs</h1>
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-8">
                <button
                  className={`${
                    isEditMode ? "btn-warning" : "btn-primary"
                  } btn w-auto text-white`}
                  onClick={handleSubmit}
                >
                  {isEditMode ? "Update" : "Create New"}
                </button>
                {isEditMode && (
                  <button
                    className="btn btn-secondary w-auto text-white"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setIsEditMode(false);
                      setName("");
                    }}
                  >
                    Cancel
                  </button>
                )}
              </div>
              <div className="g-4 mb-4 overflow-x-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data &&
                      data?.data.map((el: any) => (
                        <tr>
                          <td>{el.name}</td>
                          <td>
                            <button
                              className="btn text-primary"
                              style={{ fontSize: "18px" }}
                              onClick={() => {
                                setName(el.name);
                                setSelecedRow(el);
                                setIsEditMode(true);
                              }}
                            >
                              <EditIcon />
                            </button>
                            <button
                              className="btn text-danger"
                              style={{ fontSize: "18px" }}
                              onClick={() => {
                                // console.log(el);
                                setSelecedRow(el);
                                setDeleteOpen(true);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
