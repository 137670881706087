/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getAllLevelOne, addLevelTwoData } from "../level1/api.js";
// import { DeleteIcon } from "../../../shared/Icon.js";

const AddLevelTwo = ({ row, onClose, isEditMode }: any) => {
  const queryClient = useQueryClient();
  const [level1, setLevel1] = useState("");
  const [level2, setLevel2] = useState("");
  const [loading, setLoading] = useState(false);

  const { data } = useQuery({
    queryKey: ["getAllLevel1"],
    queryFn: getAllLevelOne,
  });

  const handleSubmmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const req = {
        name: level2,
        labelOneId: level1,
        id: isEditMode ? row._id : undefined,
      };
      const res = await addLevelTwoData(req, isEditMode);
      console.log(res);
      queryClient.invalidateQueries({ queryKey: ["getAllLevel2"] });
      toast.success("Addedd Successfully");
      onClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Failed to add");
    }
  };

  useEffect(() => {
    if (isEditMode) {
      setLevel1(row?.levelOneName?._id);
      setLevel2(row?.name);
    }
  }, [row, data]);
  return (
    <>
      <form onSubmit={handleSubmmit}>
        <div
          className="modal fade modal-toggle show"
          id="exampleModal"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content form">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Create Level 2
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <select
                  className="form-control"
                  required
                  value={level1}
                  onChange={(e) => {
                    setLevel1(e.target.value);
                  }}
                >
                  <option value={""} selected>
                    Select an option
                  </option>
                  {data?.data?.map((el: any) => (
                    <option key={el._id} value={el._id}>
                      {el.name}
                    </option>
                  ))}
                </select>
                <div className="form-group d-block mt-3">
                  <input
                    type="text"
                    style={{ border: "1px solid #e7e7e7", float: "unset" }}
                    className="form-control"
                    required
                    value={level2}
                    placeholder="Enter level 2 name"
                    onChange={(e) => {
                      setLevel2(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-danger text-white">
                  {loading ? "Saving" : "Submit"}
                </button>
                <button
                  type="submit"
                  onClick={onClose}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default AddLevelTwo;
