/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueryClient } from "@tanstack/react-query";

import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { findAndUpdatelevel3 } from "../level1/api.js";

const UpdateLevelThree = ({ row, onClose }: any) => {
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSubmmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = new FormData();

      data.append("files", selectedFile);
      data.append("id", row.id);
      data.append("name", row.name);
      data.append("displayName", displayName);
      const res = await findAndUpdatelevel3(data);
      console.log(res);
      queryClient.invalidateQueries({ queryKey: ["getBylevel1"] });
      toast.success("Updated Successfully");
      onClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Failed to Update");
    }
  };

  useEffect(() => {
    setDisplayName(row?.displayName);
  }, [row]);

  return (
    <>
      <form onSubmit={handleSubmmit}>
        <div
          className="modal fade modal-toggle show"
          id="exampleModal"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content form">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Level 3
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group mb-3">
                  <p
                    className="text-left text-black"
                    style={{ textAlign: "left", padding: 0, fontSize: "1rem" }}
                  >
                    Current File:<b>{row.name}</b>
                  </p>
                  <input
                    type="file"
                    style={{
                      border: "1px solid #e7e7e7",
                      float: "unset",
                      margin: 0,
                    }}
                    className="form-control"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    style={{
                      border: "1px solid #e7e7e7",
                      float: "unset",
                      margin: 0,
                    }}
                    value={displayName}
                    className="form-control"
                    required
                    onChange={(e) => {
                      setDisplayName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger text-white"
                  disabled={loading}
                >
                  {loading ? "Saving" : "Submit"}
                </button>
                <button
                  type="submit"
                  onClick={onClose}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default UpdateLevelThree;
