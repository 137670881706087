import React from "react";

const Sidebar = () => {
  return (
    <div id="sidebar-wrapper">
      <a href="index.html" className="brand">
        <img src="https://grpweb.com/images/grp-logo.png" alt="GRP Logo" />
      </a>
      <ul className="sidebar-nav">
        <li>
          <a href="index.html" className="active">
            <span className="home"></span>Home
          </a>
        </li>
        <li>
          <a href="#" id="about">
            <span className="about"></span>About
          </a>
          <ul className="about-menu">
            <li>
              <a href="https://www.grpweb.com/about.html">About Company</a>
            </li>
            <li>
              <a href="https://www.grpweb.com/directors.html">Directors</a>
            </li>
            <li>
              <a href="https://www.grpweb.com/grp-csr.html">CSR</a>
            </li>
            <li>
              <a href="https://www.grpweb.com/news.html">New at GRP</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#" id="Reclaim">
            <span className="reclaim"></span>Reclaim Rubber
          </a>
          <ul className="abc">
            <li className="sub1" id="sub1">
              <a href="#">Products</a>
              <ul className="sub2">
                <li>
                  <a href="https://www.grpweb.com/natural-rubber-reclaim.html">
                    Natural Rubber Reclaim
                  </a>
                </li>
                <li>
                  <a href="https://www.grpweb.com/synthetic-rubber-reclaim.html">
                    Synthetic Rubber Reclaim
                  </a>
                </li>
                <li>
                  <a href="https://www.grpweb.com/polycoat.html">Polycoat</a>
                </li>
              </ul>
            </li>
            <li className="sub4" id="sub4">
              <a href="#">Application</a>
              <ul className="sub5">
                <li>
                  <a href="https://www.grpweb.com/reclaim-applications.html">
                    Tyre Applications
                  </a>
                </li>
                <li>
                  <a href="https://www.grpweb.com/conveyor-belts.html">
                    Non-Tyre Applications
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a href="#" id="ip">
            <span className="ind-poly"></span>Engineering Plastics
          </a>
          <ul className="ip-menu">
            <li>
              <a href="https://www.grpweb.com/polymers-products.html">
                Products
              </a>
            </li>
            <li>
              <a href="https://www.grpweb.com/polymers-industry.html">
                Industry
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="https://www.grpweb.com/repurposed-polyolefins.html">
            <span className="repurposed"></span>Repurposed Polyolefins
          </a>
        </li>
        <li>
          <a href="https://www.grpweb.com/polymer-composite.html">
            <span className="polymer-composite"></span>Polymer Composite
          </a>
        </li>
        <li>
          <a href="https://www.grpweb.com/custom-die-forms.html">
            <span className="custom-die"></span>Custom Die Forms
          </a>
        </li>
        <li>
          <a href="#" id="down">
            <span className="download"></span>Downloads
          </a>
          <ul className="down-menu">
            <li>
              <a href="https://www.grpweb.com/products-download.html">
                Products
              </a>
            </li>
            <li>
              <a href="https://www.grpweb.com/corporate-download.html">
                Corporate
              </a>
            </li>
            <li>
              <a href="https://www.grpweb.com/newsletter.html">Newsletter</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="https://www.grpweb.com/investors.html">
            <span className="investors"></span>Investor
          </a>
        </li>
        <li>
          <a href="#">
            <span className="careers-new"></span>Join Us
          </a>
        </li>
        <li>
          <a href="#" id="careers">
            <span className="careers"></span>Life at GRP
          </a>
          <ul className="abcd">
            <li>
              <a href="https://www.grpweb.com/life-at-grp.html">Main page</a>
            </li>
            <li>
              <a href="https://www.grpweb.com/media-gallery.html">
                Media gallery
              </a>
            </li>
            {/* <li><a href="#">Join Us</a></li> */}
          </ul>
        </li>

        <li>
          <a href="https://www.grpweb.com/contact.html">
            <span className="contact"></span>Contact
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
