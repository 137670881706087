/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { useEffect, useState } from "react";
import {
  getAllLevelOne,
  getBylevel1,
  findAndDeleteLevel3,
} from "../level1/api";
import AddLevelThree from "./AddLevelThree";
import { DeleteIcon, EditIcon, ViewIcon } from "../../../shared/Icon";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import UpdateLevelThree from "./UpdateLevelThree";

const path = "https://grp.backendapihub.com/static/investor/";

export default function InvesterLevelThree() {
  const queryClient = useQueryClient();
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [selecedRow, setSelecedRow] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery({
    queryKey: ["getBylevel1", selectedLevel],
    queryFn: getBylevel1,
    enabled: !!selectedLevel,
  });

  const { data: level1, isFetched } = useQuery({
    queryKey: ["getAllLevel1"],
    queryFn: getAllLevelOne,
  });

  useEffect(() => {
    if (level1?.data?.length > 0) {
      setSelectedLevel(level1.data[0]._id);
    }
  }, [isFetched]);

  const hanldeDelete = async () => {
    try {
      setLoading(true);
      const req = {
        name: selecedRow?.name,
        id: selecedRow?.id,
      };
      const data = await findAndDeleteLevel3(req);
      console.log(data);
      queryClient.invalidateQueries({ queryKey: ["getBylevel1"] });
      setSelecedRow(null);
      setOpenDelete(false);
      toast.success("File Deleted Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {openAdd && (
        <AddLevelThree
          onClose={() => {
            setOpenAdd(false);
          }}
        />
      )}
      {isEditMode && (
        <UpdateLevelThree
          onClose={() => {
            setIsEditMode(false);
            setSelecedRow(null);
          }}
          row={selecedRow}
        />
      )}
      <div className="app-wrapper">
        <div className="app-content pt-3 p-md-3 p-lg-4">
          <div className="container-xl">
            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="d-flex flex-wrap align-items-center">
                <h1 className="app-page-title mb-0">Investor Tabs</h1>
              </div>

              <div className="col-lg-12">
                <div className="d-flex justify-content-between align-items-center">
                  <select
                    className="form-control"
                    required
                    style={{ width: "300px" }}
                    value={selectedLevel}
                    onChange={(e) => {
                      setSelectedLevel(e.target.value);
                    }}
                  >
                    <option value={""} selected>
                      Select an option
                    </option>
                    {level1?.data?.map((el: any) => (
                      <option key={el._id} value={el._id}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                  <button
                    className="btn-primary w-auto text-white"
                    onClick={() => {
                      setOpenAdd(true);
                    }}
                  >
                    Create New
                  </button>
                </div>
              </div>
              <div className="g-4 mb-4 overflow-x-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">files</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data?.map((el: any) => (
                        <>
                          <tr>
                            <td rowSpan={el?.documents[0]?.files?.length + 1}>
                              {el._id}
                            </td>
                          </tr>
                          {el?.documents[0]?.files.map((file: any) => (
                            <tr>
                              <td>{file.name}</td>
                              <td>
                                <div className="d-flex">
                                  <Link
                                    className="btn btn-sm text-primary"
                                    style={{ fontSize: "1rem" }}
                                    to={`${path}${file.name}`}
                                    target="_blank"
                                  >
                                    <ViewIcon />
                                  </Link>
                                  <button
                                    className="btn btn-sm text-warning"
                                    style={{ fontSize: "18px" }}
                                    onClick={() => {
                                      setIsEditMode(true);
                                      setSelecedRow({
                                        ...el,
                                        name: file.name,
                                        id: el?.documents[0]?._id,
                                        displayName: file.displayName,
                                      });
                                    }}
                                  >
                                    <EditIcon />
                                  </button>
                                  <button
                                    className="btn btn-sm text-danger"
                                    style={{ fontSize: "1rem" }}
                                    onClick={() => {
                                      setOpenDelete(true);
                                      setSelecedRow({
                                        ...el,
                                        name: file.name,
                                        id: el?.documents[0]?._id,
                                        displayName: file.displayName,
                                      });
                                    }}
                                  >
                                    <DeleteIcon />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openDelete && (
        <>
          <div
            className="modal fade modal-toggle show"
            id="exampleModal"
            data-backdrop="static"
            data-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content form">
                <div className="modal-body">
                  <h4>{selecedRow?.name}</h4>
                  <h6 className="text-light">
                    Are you sure. You want to delete this record??
                  </h6>
                </div>
                <div
                  className="modal-footer"
                  style={{ justifyContent: "center" }}
                >
                  <button
                    className="btn btn-danger text-white"
                    onClick={hanldeDelete}
                  >
                    {loading ? "Deleting" : "Yes"}
                  </button>
                  <button
                    onClick={() => {
                      setOpenDelete(false);
                      setSelecedRow(null);
                    }}
                    className="btn btn-secondary"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
}
