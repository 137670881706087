/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { toast } from "react-toastify";
import { useState } from "react";
import {
  getAllLevelOne,
  addLevelThreeData,
  getBylevelTwoByLevelOne,
} from "../level1/api.js";
import { DeleteIcon } from "../../../shared/Icon";

const AddLevelThree = ({ row, onClose }: any) => {
  const queryClient = useQueryClient();
  const [level1, setLevel1] = useState("");
  const [level2, setLevel2] = useState("");
  const [selectedFile, setSelectedFile] = useState([
    { file: null, displayName: "" },
  ]);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery({
    queryKey: ["getAllLevel1"],
    queryFn: getAllLevelOne,
  });
  const { data: levelTwo } = useQuery({
    queryKey: ["getBylevelTwoByLevelOne", level1],
    queryFn: getBylevelTwoByLevelOne,
    enabled: !!level1,
  });

  const handleFileChange = (e: any, index: any) => {
    if (e.target.files) {
      const newFile = [...selectedFile];
      newFile[index]["file"] = e.target.files[0];
      setSelectedFile(newFile);
    }
  };
  const handleNameChange = (e: any, index: any) => {
    const value = e.target.value;
    const newFile = [...selectedFile];
    newFile[index]["displayName"] = value;
    setSelectedFile(newFile);
  };

  const handleSubmmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = new FormData();
      data.append("labelTwoId", level2);
      data.append("levelOneId", level1);
      for (let i = 0; i < selectedFile.length; i++) {
        data.append("files", selectedFile[i]["file"]);
      }
      data.append(
        "displayName",
        JSON.stringify(selectedFile.map((el) => el.displayName))
      );
      const res = await addLevelThreeData(data);
      console.log(res);
      queryClient.invalidateQueries({ queryKey: ["getBylevel1"] });
      toast.success("Addedd Successfully");
      onClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Failed to add");
    }
  };
  const handleDelete = (index: any) => {
    const newFile = [...selectedFile];
    const filterEle = newFile.filter((_, i) => i !== index);
    console.log(filterEle, "filterEle");
    setSelectedFile(filterEle);
  };
  // console.log(displayName, "displayName");
  return (
    <>
      <form onSubmit={handleSubmmit}>
        <div
          className="modal fade modal-toggle show"
          id="exampleModal"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content form">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Create Level 3
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <select
                  className="form-control"
                  required
                  value={level1}
                  onChange={(e) => {
                    setLevel1(e.target.value);
                  }}
                >
                  <option value={""} selected>
                    Select an option
                  </option>
                  {data?.data?.map((el: any) => (
                    <option key={el._id} value={el._id}>
                      {el.name}
                    </option>
                  ))}
                </select>
                <div className="form-group d-block mt-3">
                  <select
                    className="form-control"
                    required
                    value={level2}
                    onChange={(e) => {
                      setLevel2(e.target.value);
                    }}
                  >
                    <option value={""} selected>
                      Select an option
                    </option>
                    {levelTwo?.map((el: any) => (
                      <option key={el._id} value={el._id}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-end">
                  <button
                    className="btn-primary text-white float-right my-3"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedFile((ps) => [
                        ...ps,
                        { file: null, displayName: "" },
                      ]);
                    }}
                  >
                    Add New
                  </button>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <td align="left">File</td>
                      <td align="left">Display Name</td>
                      <td align="left">Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedFile?.map((el: any, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="file"
                            style={{
                              border: "1px solid #e7e7e7",
                              float: "unset",
                              margin: 0,
                            }}
                            key={index}
                            className="form-control"
                            name={`file.${index}.upload`}
                            required
                            onChange={(e) => {
                              handleFileChange(e, index);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            style={{
                              border: "1px solid #e7e7e7",
                              float: "unset",
                              margin: 0,
                            }}
                            key={index}
                            value={el["displayName"]}
                            className="form-control"
                            name={`file.${index}.displayName`}
                            required
                            onChange={(e) => {
                              handleNameChange(e, index);
                            }}
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(index);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger text-white"
                  disabled={loading}
                >
                  {loading ? "Saving" : "Submit"}
                </button>
                <button
                  type="submit"
                  onClick={onClose}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default AddLevelThree;
