const USERS = "users";
const TOKEN = "Token";

const ADMIN = "admin";
const USER = "user";

const COLORS_PRODUCT = [
  "#FF5733",
  "#33FF57",
  "#5733FF",
  "#FF33A1",
  "#A1FF33",
  "#3357FF",
  "#FF3367",
  "#67FF33",
  "#3367FF",
  "#FFA133",
  "#33A1FF",
  "#A133FF",
  "#FF3333",
  "#33FFA1",
  "#A1FFA1",
  "#A1A1FF",
  "#FFA1A1",
  "#A1FFA1",
  "#FF33FF",
  "#33FFFF",
  "#FFFF33",
  "#33A133",
  "#A13333",
  "#33A1A1",
  "#A1A133",
  "#A133A1",
  "#33A1FF",
  "#A1FF33",
  "#33FFA1",
  "#A1A1A1",
  "#FFD700",
  "#FF4500",
  "#8B4513",
  "#2E8B57",
  "#4169E1",
  "#8A2BE2",
  "#A52A2A",
  "#D2691E",
  "#00CED1",
  "#4B0082",
  "#FF8C00",
  "#7CFC00",
  "#8B008B",
  "#2F4F4F",
  "#9932CC",
  "#00FA9A",
  "#48D1CC",
  "#FF69B4",
  "#008080",
  "#800080",
  "#4682B4",
];

export { USERS, TOKEN, ADMIN, USER, COLORS_PRODUCT };
