import { Link } from "react-router-dom";

import logo from "../../assets/img/admin/grp-logo.png";

import { CategoriesIcon } from "../shared/Icon";
import { useState, useEffect } from "react";
import clsx from "clsx";

import { NavLinkType } from "./types";
import { getItemFromCookie } from "../../shared/helpers/utils";

const allRoutes = [
  { title: "Job Categories", path: "job-category-list", role: "hr" },
  { title: "Create New Job", path: "create-new-job", role: "hr" },
  { title: "Job List", path: "job-list", role: "hr" },
  { title: "Application List", path: "application-list", role: "hr" },
  { title: "Inventer Level One", path: "level-one", role: "legal" },
  { title: "Investor level 2", path: "level-two", role: "legal" },
  { title: "Investor level 3", path: "level-three", role: "legal" },
];
const NavLink = ({
  href,
  icon,
  text,
  isActive,
  onClick,
}: NavLinkType & { isActive: boolean; onClick: () => void }) => {
  const activeClassName = isActive ? "active" : "";
  return (
    <li className={`nav-item ${activeClassName}`}>
      <Link className="nav-link" to={href} onClick={onClick}>
        <span>{icon}</span>
        <span className="nav-link-text">{text}</span>
      </Link>
    </li>
  );
};

const SideBar = ({ sidebarOpen, handleClose }: any) => {
  const [activeLink, setActiveLink] = useState("#0");
  const [menus, setMenus] = useState([]);
  const role = getItemFromCookie("role");
  console.log(role, "role");
  useEffect(() => {
    const currentRoute = window.location.pathname;
    if (currentRoute === "admin/job-category-list") {
      setActiveLink("#0");
    } else if (currentRoute === "admin/create-new-job") {
      setActiveLink("#1");
    } else if (currentRoute === "admin/job-list") {
      setActiveLink("#2");
    } else if (currentRoute === "admin/application-list") {
      setActiveLink("#3");
    }
  }, []);

  useEffect(() => {
    if (role === "legal") {
      setMenus(allRoutes.filter((el) => el.role === "legal"));
    } else if (role === "hr") {
      setMenus(allRoutes.filter((el) => el?.role === "hr"));
    } else {
      setMenus(allRoutes);
    }
  }, [role]);
  const handleNavLinkClick = (href: string) => {
    setActiveLink(href);
    handleClose();
  };

  return (
    <div
      id="app-sidepanel"
      className={clsx({ "sidepanel-visible": sidebarOpen }, "app-sidepanel")}
    >
      <div id="sidepanel-drop" className="sidepanel-drop"></div>
      <div className="sidepanel-inner d-flex flex-column">
        <button
          id="sidepanel-close"
          className="sidepanel-close d-xl-none"
          onClick={handleClose}
        >
          &times;
        </button>
        <div className="app-branding">
          <a className="app-logo" href="index.html">
            <img className="logo-icon me-2" src={logo} alt="logo" />
          </a>
        </div>
        <nav className="app-nav app-nav-main flex-grow-1">
          <ul className="app-menu list-unstyled accordion" id="menu-accordion">
            {menus.map((el) => (
              <NavLink
                href={el.path}
                text={el.title}
                icon={<CategoriesIcon />}
                isActive={el.path === activeLink}
                onClick={() => handleNavLinkClick(el.path)}
              />
            ))}

            {/* <NavLink
              href="create-new-job"
              text="Create New Job"
              icon={<Plus />}
              isActive={activeLink === "#1"}
              onClick={() => handleNavLinkClick("#1")}
            />
            <NavLink
              href="job-list"
              text="Job List"
              icon={<CardList />}
              isActive={activeLink === "#2"}
              onClick={() => handleNavLinkClick("#2")}
            />
            <NavLink
              href="application-list"
              text="Application List"
              icon={<ApplicationIcon />}
              isActive={activeLink === "#3"}
              onClick={() => handleNavLinkClick("#3")}
            />
            <NavLink
              href="level-one"
              text="Inventer Level One"
              icon={<ApplicationIcon />}
              isActive={activeLink === "#4"}
              onClick={() => handleNavLinkClick("#4")}
            />
            <NavLink
              href="level-two"
              text="Investor level 2"
              icon={<ApplicationIcon />}
              isActive={activeLink === "#5"}
              onClick={() => handleNavLinkClick("#5")}
            />
            <NavLink
              href="level-three"
              text="Investor level 3 "
              icon={<ApplicationIcon />}
              isActive={activeLink === "#6"}
              onClick={() => handleNavLinkClick("#6")}
            /> */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
