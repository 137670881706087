import React, { useState } from "react";
import { findAndDelete } from "../master/level1/api.js";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
export default function Delete({ row, onClose, type, reload }: any) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    try {
      setLoading(true);
      const req = {
        type: type,
        id: row._id,
      };
      const data = await findAndDelete(req);
      console.log(data);
      toast.success("Deleted Successfully");
      queryClient.invalidateQueries({ queryKey: [reload] });
      onClose();
    } catch (error) {
      console.log(error);
      toast.error("Failed to Delete");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  console.log(row, "row");
  return (
    <>
      <div
        className="modal fade modal-toggle show"
        id="exampleModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content form">
            <div className="modal-body">
              <h4>{row?.name}</h4>
              <h6 className="text-light">
                Are you sure. You want to delete this record??
              </h6>
            </div>
            <div className="modal-footer" style={{ justifyContent: "center" }}>
              <button
                className="btn btn-danger text-white"
                onClick={handleDelete}
              >
                {loading ? "Deleting" : "Yes"}
              </button>
              <button onClick={onClose} className="btn btn-secondary">
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-backdrop fade show"></div>
    </>
  );
}
